<template>
  <main class="container-fluid overflow-initial-content bg-white">
      <div class="row row-100-vh">
        <section class="col-11 main-content m-auto">
            <b-row>
                <b-col lg="12">
                    <InitialHeader/>
                </b-col>
                <b-col lg="7">
                    <div class="container-titulo">
                        <h1 class="text-dark display-4">Calendário de Campanhas</h1>
                    </div>
                    <div class="content-texts">
                        <div class="texts">
                            <p class="text-dark font-size-14">Saiba quando as Campanhas de Vacinação estarão disponíveis no seu estado. Planeje com antecedência a adesão de vacinas para a sua empresa.</p><br>
                        </div>
                        <div class="m-0 p-0 col-lg-6">
                            <v-select 
                                id="select-campaign"
                                v-model="stateSelected"
                                :options="states"
                                label="nome"
                                @input="loadCampaignUf"
                                placeholder="Selecione um Estado"
                                :clearable="false"
                            >
                            <span slot="no-options">Nenhuma opção selecionável.</span>
                            </v-select>
                        </div>
                        <div v-if="loadingData" class="d-flex justify-content-center col-6 my-3">
                            <div class="spinner-border text-custom-blue" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="mt-4 mb-2" v-if="!loadingData && stateSelected">
                            <div class="col-lg-6 ml-n1">
                                <h2 class="text-custom-blue mb-2">{{stateSelected.nome}}</h2>
                            </div>
                            <div class="targetBackground"  v-if="stateRedirect == stateSelected.uf">
                                <a
                                    :href="redirectURLByUF(stateSelected.uf)" 
                                    target="_blank"
                                >
                                    <div class="d-flex align-items-center">
                                        <b-img
                                            :src="require('@/assets/custom-icons/target.svg')"
                                            rounded="circle"
                                            v-bind="targetProps"
                                        />
                                        <span class="targetIconSubtitle">Saiba mais sobre a vacinação neste estado.</span>
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div class="col-lg-6 ml-n1" v-if="!loadingData && stateSelected">
                            <div v-if="withoutCampaign">
                                <span class="font-size-14">Nenhuma Campanha listada</span>
                            </div>
                            <div v-else>
                                <b-list-group 
                                v-for="campaign in campaigns"
                                :key="campaign.descricao"
                                >
                                    <b-list-group-item class="pl-0 border-top-0 border-right-0 border-left-0">
                                        <span class="text-custom-blue font-size-16">{{campaign.descricao}}</span><br>
                                        <sub 
                                        v-for="period in campaign.cronograma" 
                                        :key="period.tipo"
                                        class="text-capitalize">
                                            {{ period.tipo }}: {{ moment(period.inicio).format('DD MMM YYYY') }} - {{ moment(period.termino).format('DD MMM YYYY')}} <br>
                                        </sub>
                                    </b-list-group-item>
                                </b-list-group>
                                <div class="buttons-container mt-3">
                                    <b-button
                                        :to="{ name: 'auth-login' }"
                                        id="button-empresas"
                                        class="mr-3 mb-4 rounded-pill "
                                        variant="custom-blue"
                                        size="lg"
                                    >Vacinas para Empresas</b-button>
                                </div>
                            </div>
                        </div>

                    </div>
                </b-col>
                <b-col class="mt-5 m-lg-0" lg="5">
                    <b-img-lazy fluid center :src="largeCalendar" alt="large-calendar"></b-img-lazy>
                </b-col>
            </b-row>
        </section>
        <FooterInitial/>
      </div>
  </main>
</template>

<script>
import largeCalendar from '@/assets/custom-images/initial-pages/calendario-lg.png';
import FooterInitial from '@/views/components/custom/footer/FooterInitial.vue';
import vSelect from 'vue-select'
import moment from 'moment'
import "moment/locale/pt";

import { BButton, BRow, BCol, BImgLazy, BListGroup, BListGroupItem, BImg} from 'bootstrap-vue';
import InitialHeader from '@/views/components/custom/page-header/InitialHeader.vue';

export default {
    title: 'Calendário de Campanhas',

    components: {
        BButton,
        BRow,
        BCol,
        BImgLazy,
        BListGroup,
        BListGroupItem,
        BImg,
        vSelect,
        FooterInitial,
        InitialHeader
    },

    mounted() {
        moment.locale('pt')
        this.$http.get(this.$api.getEstados()).then(({data}) => {
            this.states = data;
            this.states.unshift({
                    uf: null,
                    nome: 'Mais de um estado'
                });
        });
    },

    data() {
        return {
            moment,
            largeCalendar,
            states: [],
            stateSelected: null,
            campaigns: [],
            withoutCampaign: false,
            loadingData: false,
            targetProps: { 
                width: 24, 
                height: 24 
            },
            stateRedirect: null
        }
    },
    methods: {
        async loadCampaignUf(){
            if(!this.stateSelected){
                return;
            }

            if(this.stateSelected.uf == null){
                this.redirectToForm();
            }

            this.loadingData = true;

            await this.$http.get(this.$api.campanhaUf(this.stateSelected.uf)).then(({data}) => {
                this.campaigns = data;
                
                this.withoutCampaign = this.campaigns.length == 0;
                this.loadingData = false;
                this.getUfCampaignRedirect(this.stateSelected.uf);
            });
        },

        async getUfCampaignRedirect(uf) {
            await this.$http.get(this.$api.CampanhaCalendarioRedirecionamento(uf)).then(response => {
                if(response.status == 200) {
                    this.stateRedirect = response.data.uf;
                }
            }).catch(error => {
                if(error.request.status == 500) {
                    this.stateRedirect = null;
                }
            })
        },

        redirectURLByUF(uf){
            return`${process.env.VUE_APP_APP_URL}/${uf.toLowerCase()}`
        },
        redirectToForm(){
            this.$router.push({name: 'budget-contact'});
        }
    }
}
</script>

<style lang="scss">
    @import "./style.scss";
</style>
